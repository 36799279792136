import TopicLayout from "../components/TopicLayout";
import TopicNavigation from "../components/TopicNavigation";
import {useNavigate} from "react-router-dom";


function Topic3() {
    const navigate = useNavigate();

    return (
        <TopicLayout>
            <h1 style={{
                fontWeight: '500',
                fontSize: '70px',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: '1vh',
                marginBottom: 0
            }}>
                Corsi di <br/> formazione
            </h1>
            <img src={'/icons/menu-icon-3.svg'} style={{width: '20%', margin: 'auto', paddingTop: '3vh'}}/>

            <p style={{
                fontWeight: 'normal',
                fontSize: '38px',
                marginTop: '2vh',
                marginBottom: 0,
                textAlign: 'center',
                paddingTop: '2vh'
            }}>
                Attraverso i nostri <span style={{fontWeight: '700'}}>corsi di formazione</span>, disponibili
                online <br/>
                per tutti e all’interno dei nostri <span
                style={{fontWeight: '700', backgroundColor: '#007cc2', color: 'white'}}  onClick={() => {navigate('/topic/2');}} >Spazi Interattivi </span>, <br/>
                vogliamo contribuire a colmare il gap di competenze <br/>
                e aiutare imprese, studenti e professionisti <br/>
                ad approfondire le proprie conoscenze sull’IA, <br/>
                qualsiasi sia il loro livello e obiettivo professionale.
            </p>

            <p style={{
                fontWeight: '700',
                fontSize: '35px',
                textAlign: 'right',
                padding: '5vw',
                marginLeft: 'auto',
                marginTop: '3.5vh',
                textTransform: 'uppercase',
                paddingBottom: '0px'
            }}>
                Scopri<br/>
                la nostra<br/>
                offerta<br/>
                formativa<br/>
                <span style={{color: '#007cc2'}}>↘</span>

            </p>

            <img style={{width: '18%', paddingRight: '5vw', marginLeft: 'auto', paddingTop: '0px'}}
                 src={'/qrcodes/qr-section-3.svg'}/>

            <img style={{width:'53%',position:'absolute', bottom:'7vh', left:0}}
                 src={'/images/illustration-section-3.svg'}/>

            <TopicNavigation currentId={3}/>
        </TopicLayout>
    )
}

export default Topic3;