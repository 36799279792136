import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/menu');  // Cambia con il percorso che ti serve
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0,
            color: '#5f6368'
        }}>

            <img style={{width: '90%', padding: '70px', paddingBottom:'0px !important'}} src={'/images/home-img.svg'}/>
            <div style={{width: '90%', padding: '70px', paddingTop:0}}>
                <p style={{fontWeight: 'normal', fontSize: '38px', marginTop: 0}}>L’intelligenza artificiale sta
                    cambiando il
                    modo <br/>
                    in cui le imprese immaginano il proprio futuro. <br/></p>
                <p style={{fontWeight: 'normal', fontSize: '38px'}}>
                    In questa fase trasformativa, con il progetto <br/>
                    <span style={{fontWeight: 'bold', color: '#007cc2'}}>IA per il Made in Italy</span> Google vuole
                    essere al loro <br/>
                    fianco per aiutarle a coglierne le grandi opportunità, <br/>
                    con un approccio audace e responsabile e una <br/>
                    particolare attenzione ai settori del Made in Italy, <br/>
                    simbolo dell’eccellenza italiana nel mondo.
                    <br/> <br/>
                    <span style={{fontWeight: 'bold'}}>grow.google/IAperMadeinItaly</span>
                </p>
            </div>
            <img style={{width: '15%', padding: '70px', marginRight: 'auto', paddingBottom: '20px', paddingTop:0}} src={'/qrcodes/qr-home.svg'}/>

            <img style={{position:'absolute', width: '24%', bottom:'7vh', right:'7vw'}} onClick={handleImageClick} src={'/icons/inizia-icon.png'}/>


        </div>
    );
}

export default Home;