import {Link} from "react-router-dom";

const arrowRight = {
    position:'fixed',
    top: "50%",
    transform: 'translateY(-50%)',
    fontSize: '2rem',
    textDecoration: 'none',
    color: '#000',
    padding: '0.5rem',
    background: 'rgba(255, 255, 255, 0.7)',
    zIndex: '1000',
    right: '2vw'
}

const arrowLeft = {
    position:'fixed',
    top: "50%",
    transform: 'translateY(-50%)',
    fontSize: '2rem',
    textDecoration: 'none',
    color: '#000',
    padding: '0.5rem',
    background: 'rgba(255, 255, 255, 0.7)',
    zIndex: '1000',
    left: '2vw'
}

function TopicNavigation({ currentId }) {
    const totalTopics = 6;

    const prevId = currentId === 1 ? totalTopics : currentId - 1;
    const nextId = currentId === totalTopics ? 1 : currentId + 1;

    return (
        <>
            <Link to={`/topic/${prevId}`} style={arrowLeft}>
                &#8592;
            </Link>
            <Link to={`/topic/${nextId}`} style={arrowRight}>
                &#8594;
            </Link>
        </>
    );
}

export default TopicNavigation;