import TopicLayout from "../components/TopicLayout";
import TopicNavigation from "../components/TopicNavigation";


function Topic5() {
    return (
        <TopicLayout>
            <h1 style={{
                fontWeight: '500',
                fontSize: '70px',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: '1vh',
                marginBottom: 0
            }}>
                AI <br/> Smart Report
            </h1>
            <img src={'/icons/menu-icon-5.svg'} style={{width: '15%', margin: 'auto', paddingTop: '3vh'}}/>

            <p style={{
                fontWeight: 'normal',
                fontSize: '38px',
                marginTop: '2vh',
                marginBottom: 0,
                textAlign: 'center',
                paddingTop: '2vh'
            }}>
                Con l’<span style={{fontWeight: '700'}}>AI Smart Report</span> - uno strumento senza costi <br/>
                realizzato sulla base di una ricerca condotta con la <br/>
                School of Management del Politecnico di Milano - <br/>
                vogliamo supportare le imprese del Made in Italy a <br/>
                orientarsi nel mondo dell’intelligenza artificiale <br/>
                scoprendone le possibili applicazioni e i relativi <br/>
                benefici attraverso un report personalizzato.
            </p>

            <p style={{
                fontWeight: '700',
                fontSize: '35px',
                textAlign: 'right',
                padding: '5vw',
                marginLeft: 'auto',
                marginTop: '0.5vh',
                textTransform: 'uppercase',
                paddingBottom: '0px'
            }}>
                SCARICA LA RICERCA <br/>
                O IL REPORT<br/>
                PERSONALIZZATO <br/>
                PER LA TUA AZIENDA<br/>
                <span style={{color: '#007cc2'}}>↘</span>

            </p>

            <img style={{width: '18%', paddingRight: '5vw', marginLeft: 'auto', paddingTop: '0px'}}
                 src={'/qrcodes/qr-section-5.svg'}/>

            <img style={{width: '40%', position: 'absolute', bottom: '-2vh', left: '5vw'}}
                 src={'/images/illustration-section-5.svg'}/>

            <TopicNavigation currentId={5}/>
        </TopicLayout>
    )
}

export default Topic5;