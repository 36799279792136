import TopicLayout from "../components/TopicLayout";
import TopicNavigation from "../components/TopicNavigation";
import {useNavigate} from "react-router-dom";


function Topic2() {
    const navigate = useNavigate();

    return (
        <TopicLayout>
            <h1 style={{
                fontWeight: '500',
                fontSize: '70px',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: '1vh',
                marginBottom: 0
            }}>
                Spazi <br/> Interattivi
            </h1>
            <img src={'/icons/menu-icon-2.svg'} style={{width: '15%', margin: 'auto', paddingTop: '3vh'}}/>

            <p style={{
                fontWeight: 'normal',
                fontSize: '38px',
                marginTop: '2vh',
                marginBottom: 0,
                textAlign: 'center',
                paddingTop: '2vh'
            }}>
                Con i nostri <span style={{fontWeight: '700'}}>Spazi Interattivi temporanei</span>, vogliamo
                essere <br/>
                più vicini alle imprese, agli studenti e ai professionisti <br/>
                che operano nei luoghi chiave del Made in Italy, mostrando <br/>
                loro le potenzialità dell’IA con <span style={{
                    fontWeight: '700',
                    backgroundColor: '#00a248',
                    color: 'white'
                }}
                      onClick={() => {
                          navigate('/topic/1');
                      }}>installazioni ispirazionali
                </span> <br/>
                e offrendo <span style={{fontWeight: '700', backgroundColor: '#00a248', color: 'white'}}
                                 onClick={() => {
                                     navigate('/topic/4');
                                 }}>consulenze dedicate</span>
                e <span
                style={{fontWeight: '700', backgroundColor: '#00a248', color: 'white'}} onClick={() => {
                navigate('/topic/3');
            }}>corsi di formazione</span> <br/>
                sull’intelligenza artificiale, in loco e senza costi.
            </p>

            {/*<h1 style={{
                fontWeight: '500',
                fontSize: '35px',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: '5vh'
            }}>
                Guarda dove abbiamo aperto i nostri spazi <br/>
                e scopri le prossime tappe.
            </h1>*/}

            <p style={{
                fontWeight: '700',
                fontSize: '35px',
                textAlign: 'right',
                padding: '5vw',
                marginLeft: 'auto',
                marginTop: '5vh',
                textTransform: 'uppercase',
                paddingBottom: '0px'
            }}>
                Guarda dove <br/>
                Abbiamo aperto <br/>
                i nostri spazi<br/>
                <span style={{color: '#007cc2'}}>↘</span>

            </p>

            <img style={{width: '18%', paddingRight: '5vw', marginLeft: 'auto'}}
                 src={'/qrcodes/qr-section-2.svg'}/>

            <img style={{width: '64%', position: 'absolute', bottom: '-3vh', left: '-2vw'}}
                 src={'/images/illustration-section-2.svg'}/>

            <TopicNavigation currentId={2}/>
        </TopicLayout>
    )
}

export default Topic2;