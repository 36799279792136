import {Link, useNavigate} from "react-router-dom";

const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
    padding: '20px',
    width: '85%',
};

const gridItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '20px'
};

const textStyle = {
    marginTop: '2vh',
    fontSize: '40px',
    fontWeight: '500',
};

const imgStyle = {
    width: '30%'
}

function Menu() {
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/');
    };

    const items = [
        {icon: <img src={'/icons/menu-icon-1.svg'} style={imgStyle}/>, label: <>Installazioni <br/> ispirazionali</>},
        {icon: <img src={'/icons/menu-icon-2.svg'} style={imgStyle}/>, label: <>Spazi <br/> Interattivi</>},
        {icon: <img src={'/icons/menu-icon-3.svg'} style={{width:'48%'}}/>, label: <>Corsi di <br/> formazione</>},
        {icon: <img src={'/icons/menu-icon-4.svg'} style={imgStyle}/>, label: <>Consulenze <br/> dedicate</>},
        {icon: <img src={'/icons/menu-icon-5.svg'} style={imgStyle}/>, label: <>AI <br/> Smart Report</>},
        {icon: <img src={'/icons/menu-icon-6.svg'} style={imgStyle}/>, label: <>Progetti di <br/> innovazione</>}
    ];

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0,
            color: '#5f6368'
        }}>
            <img style={{width: '30%', marginTop: '5vh', marginBottom: '1vh'}} src={'/images/google-logo.svg'}/>
            <h1 style={{fontWeight: '500', fontSize: '70px', textAlign: 'center'}}>IA PER IL <br/>MADE IN ITALY</h1>

            <div style={gridContainerStyle}>
                {items.map((item, index) => (
                    <Link style={{color:'#5f6368', textDecoration:'none'}} key={index+1} to={`/topic/${index+1}`} className="menu-item">
                        <div key={index+1} style={gridItemStyle}>
                            {item.icon}
                            <p style={textStyle}>{item.label}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <img style={{position:'absolute', width: '33.5%', bottom:'7vh', right:'7vw'}} onClick={handleImageClick} src={'/icons/homepage-icon.png'}/>

        </div>
    );
}

export default Menu;