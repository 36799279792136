import TopicLayout from "../components/TopicLayout";
import TopicNavigation from "../components/TopicNavigation";
import {Link} from "react-router-dom";

const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    width: '90%',
    margin:'auto',
    gap:'20px'
};

const gridItemStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    padding: '20px',
    fontWeight:'500',
    fontSize: '38px'
};

function Topic1() {
    return (
        <TopicLayout>
            <h1 style={{fontWeight: '500', fontSize: '70px', textAlign: 'center', textTransform: 'uppercase', marginTop:'1vh', marginBottom:0}}>
                Installazioni <br/> ispirazionali
            </h1>
            <img src={'/icons/menu-icon-1.svg'} style={{width: '15%', margin: 'auto', paddingTop: '3vh'}}/>

            <p style={{
                fontWeight: 'normal',
                fontSize: '38px',
                marginTop: '2vh',
                marginBottom: 0,
                textAlign: 'center',
                paddingTop: '2vh'
            }}>
                Per aiutare le imprese a comprendere più <br/>
                concretamente le potenzialità dell’intelligenza <br/>
                artificiale, abbiamo realizzato una serie <br/>
                di <span style={{fontWeight: '700'}}>installazioni ispirazionali</span> che esprimono <br/>
                con un approccio interattivo e scenografico <br/>
                alcuni casi d’uso dell'IA nei 4 settori <br/>
                chiave del Made in Italy:
            </p>

            <div style={gridContainerStyle}>
                <div style={gridItemStyle}>
                    <img src={'/icons/section-1-1.svg'} style={{width: '20%'}}/>
                    <p style={{marginLeft: '2vw'}}>Abbigliamento</p>
                </div>
                <div style={gridItemStyle}>
                    <img src={'/icons/section-1-2.svg'} style={{width: '20%'}}/>
                    <p style={{marginLeft: '2vw'}}>Arredamento</p>
                </div>
                <div style={gridItemStyle}>
                    <img src={'/icons/section-1-3.svg'} style={{width: '15%'}}/>
                    <p style={{marginLeft: '2vw'}}>Agroalimentare</p>
                </div>
                <div style={gridItemStyle}>
                    <img src={'/icons/section-1-4.svg'} style={{width: '20%'}}/>
                    <p style={{marginLeft: '2vw'}}>Meccanica</p>
                </div>
            </div>

            <h1 style={{fontWeight: '500', fontSize: '40px', textAlign: 'center', textTransform: 'uppercase', marginTop:'3vh'}}>
                VISITA IL NOSTRO SPAZIO A COMOLAKE2024<br/>PER VEDERE ALCUNE INSTALLAZIONI
            </h1>

            <TopicNavigation currentId={1}/>
        </TopicLayout>
    )
}

export default Topic1;